/** Author: Draco Chan < draco.chan@butterfly.com.au / im@dracochan.com >
 * Scale and fit image into a container, avoid empty gaps
 * Version: 0.3
 * Known issue: None
 * Requirement: jQuery 1.8+
 * Usage:
jQuery(document).ready(function() {
	jQuery('img.fitImage').fitImage(function(img){
		var container = jQuery(img).closest('div');
		return container;
	});
});
 * Parameters:
getContainer: a function to get specific container of the image (this cannot be done automatically).
cssReady: Script may not be able to get width and height of container properly in mobile/tablet. CSS will be used, so make sure image is able to scale to width:100%; or height:100%;
centerImage: put image in the center
 **/
 
jQuery.fn.fitImage = function(getContainer, cssReady, centerImage){
	return this.each(function() {
		if(getContainer===undefined)
			return true;
		if(cssReady===undefined)
			cssReady = false;
		
		var img = jQuery(this);
		var init = function() {
			var hidden = jQuery('<div></div>').appendTo('body').css({
				'position': 'absolute',
				'top': -9999
			});
			var img2 = img.clone();
			img2.removeAttr('class');
			img2.removeAttr('style');
			hidden.append(img2);
			var imgRatio = img2.width()/img2.height(); 
			hidden.remove();
			if (imgRatio == Number.POSITIVE_INFINITY || imgRatio == Number.NEGATIVE_INFINITY) {
				return true;	
			}
			img.addClass('fitImage');
			img.css('max-width', 'none');
			img.css('max-height', 'none');
			if(centerImage) {
				img.css('margin-right', 'auto');
			}
			var fitIt = function () {
				var imgContainer = getContainer(img);
				if(imgContainer.width()/imgContainer.height()>imgRatio) {
					img.css('width', cssReady? '100%' : imgContainer.width()+'px');
					img.css('height', 'auto');
					if(centerImage) {
						img.css('margin-left', 'auto');
					}
				} else {
					img.css('width', 'auto');
					img.css('height', cssReady? '100%' : imgContainer.height()+'px');
					if(centerImage) {
						img.css('margin-left', '-'+(img.width()-imgContainer.width())/2+'px');
					}
				}
			};
			jQuery(window).resize(function(){
				setTimeout(fitIt, 50); /*avoid buggy browser maximise button*/
			});
			fitIt();
		};
		
		if (jQuery(this).prop('complete')) { 
			init();
		} else {
			jQuery(this).load(init);
		}
	});
};